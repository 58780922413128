/*
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
import $ from '@sv/jquery';
import sv from '@sv/core';

$.fn.sortableViewLayout = function () {
  // Restricts so only views belonging to this view container is processed (not views in views)
  function getViews($layout, $elem) {
    var views = [];
    var allViews = $layout.sortable('toArray');
    for (var position = 0; position < allViews.length; position++) {
      var $view = $elem.find('#' + allViews[position]);
      var $viewContainer = $view.closest('.sv-sortable-view-container');
      if ($viewContainer.attr('id') === $elem.attr('id')) {
        views.push($view.attr('id'));
      }
    }
    return views;
  }

  return this.each(function () {
    var $sortableViewLayout = $(this);
    var divId = $sortableViewLayout.attr('id');
    var horizontal = $sortableViewLayout.hasClass('sv-horizontal');

    if ($sortableViewLayout.hasClass('ui-sortable')) {
      $sortableViewLayout.sortable('destroy');
    }

    var connectWith = $sortableViewLayout.data('connect-with');
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      $sortableViewLayout.sortable({
        containment: connectWith ? false : '#' + divId,
        connectWith: connectWith ? '.sv-sortable-view-container' : false,
        placeholder: 'sv-view-selection-placeholder',
        forcePlaceholderSize: true,
        distance: 10,
        items: '.sv-view-mouseover',
        opacity: 0.6,
        stop: function (event, ui) {
          ui.item.hide();
          ui.item.show('blind');
        },
        update: function (event, ui) {
          var $sortable = $(this);
          if ($sortable.find(ui.item).length > 0) {
            if (horizontal) {
              ui.item.addClass('sv-horizontal-column');
            } else {
              ui.item.removeClass('sv-horizontal-column');
            }
          }

          var views = getViews($sortable, $sortableViewLayout);
          var newOrder = views.join(',');
          newOrder = newOrder.replace(/svid|,,/g, '');
          newOrder = newOrder.replace(/_/g, '.');

          $.post(
            $sortableViewLayout.data('view-container-url') +
              '&return=none&updateViews=true&viewId=' +
              newOrder
          );
          var $viewContainers = $sortableViewLayout.find(
            '.sv-sortable-view-container'
          );

          $viewContainers.push($sortableViewLayout);
        },
      });
    }
  });
};

$(function () {
  var $body = $('body');

  $body.on('click', '.sv-view-selector', function () {
    var $viewSelector = $(this),
      href = $viewSelector.attr('href'),
      id = $viewSelector.attr('id').substr(15); // Substring prefix 'sv-selector-id-'

    var $dialogContainer = $('#sv-view-selector-dialog-' + id);

    if ($dialogContainer.length > 0) {
      $dialogContainer.svDialog('open');
    } else {
      $dialogContainer = $('<div/>', {
        id: 'sv-view-selector-dialog-' + id,
      });

      $dialogContainer.on('open:dialog.sv-dialog', function () {
        var $this = $(this);
        $this.find('.sv-custom-views-list').sortable({
          containment: $this.closest('.sv-dialog'),
          axis: 'y',
          delay: 300,
        });
      });

      $dialogContainer.svDialog({
        title: sv.i18n.getText('util.viewutil', 'selectView'),
        open: true,
        url: href,
        modal: true,
        buttons: [
          {
            text: sv.i18n.getText('common', 'save'),
            type: 'primary',
            callback: function () {
              var $form = $dialogContainer.find('form');
              $.post(href, $form.serialize(), function () {
                $.get(sv[id].layoutURL, function (data) {
                  var $el = $('#' + id);

                  $el.html(data);
                  $('.sv-sortable-view-container').sortableViewLayout();
                  if ($el.find('.sv-lazy-load-img').length) {
                    window.svyall &&
                      window.svyall({ lazyClass: 'sv-lazy-load-img' });
                  }

                  $dialogContainer.svDialog('close');
                });
              });
            },
          },
          {
            text: sv.i18n.getText('common', 'cancel'),
            type: 'link',
            callback: function () {
              $dialogContainer.svDialog('close');
            },
          },
        ],
      });
    }

    return false;
  });

  function buildLayoutURL($element, $parent, state) {
    var parentId = $parent.attr('id').replace('svid', '').replace('_', '.'),
      href = $element.attr('href'),
      pageId = href.substring(href.indexOf('page=') + 5, href.length);

    return (
      '/' +
      pageId +
      '/' +
      parentId +
      '.htm?sv.state=' +
      state +
      '&sv.url=' +
      parentId
    );
  }

  $body.on('click', '.sv-layout-minimize', function () {
    var $this = $(this),
      $parent = $this.closest('[id^=svid10_]'),
      url = buildLayoutURL($this, $parent, 'minimized');
    $.get(url, function (data) {
      $this.closest('.sv-toolbar').siblings().hide();
      $parent.html(data);
    });

    return false;
  });

  $body.on('click', '.sv-layout-restore', function () {
    var $this = $(this),
      $parent = $this.closest('[id^=svid10_]'),
      url = buildLayoutURL($this, $parent, 'normal');
    $.get(url, function (data) {
      $parent.html(data).show();
    });

    return false;
  });

  $body.on('click', '.sv-portlet-restore', function () {
    //Fadein - normalt state on the layout
    var $this = $(this),
      $parent = $this.closest('.pagecontent'),
      parentId = $parent.attr('id').replace('svid', '').replace('_', '.'),
      href = $this.attr('href'),
      pageId = href.substring(href.indexOf('page=') + 5, href.length),
      query = href.substring(href.indexOf('?'), href.length),
      url = '/' + pageId + '/' + parentId + '.htm' + query;

    $.get(url, function (data) {
      $parent.html(data).show();
    });

    return false;
  });

  var $sortableContainers = $('.sv-sortable-view-container');

  if ($sortableContainers.length) {
    if ($.ui && $.ui.sortable) {
      $sortableContainers.sortableViewLayout();
    } else {
      import(
        /* webpackChunkName: "viewUtilPlugins" */ './vendor/viewUtilPlugins.js'
      ).then(() => $sortableContainers.sortableViewLayout());
    }
  }
});
